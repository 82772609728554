import React, { useState } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import "./App.css"; // Ensure to import the CSS for styling

const itemsList = [
  {
    id: "LINUX-VPS-0",
    description: "LINUX VPS-0",
    vCPU: 1,
    ram: 1,
    ssd: 10,
    price: 400,
  },
  {
    id: "LINUX-VPS-1",
    description: "LINUX VPS-1",
    vCPU: 1,
    ram: 2,
    ssd: 10,
    price: 500,
  },
  {
    id: "LINUX-VPS-2",
    description: "LINUX VPS-2",
    vCPU: 2,
    ram: 4,
    ssd: 10,
    price: 600,
  },
  {
    id: "LINUX-VPS-3",
    description: "LINUX VPS-3",
    vCPU: 4,
    ram: 8,
    ssd: 30,
    price: 1000,
  },
  {
    id: "WINDOWS-RDP-1",
    description: "WINDOWS RDP-1",
    vCPU: 2,
    ram: 4,
    ssd: 30,
    price: 700,
  },
  {
    id: "WINDOWS-RDP-2",
    description: "WINDOWS RDP-2",
    vCPU: 4,
    ram: 8,
    ssd: 50,
    price: 1200,
  },
  {
    id: "WINDOWS-RDP-3",
    description: "WINDOWS RDP-3",
    vCPU: 6,
    ram: 16,
    ssd: 30,
    price: 1800,
  },
];

const App = () => {
  const [invoiceData, setInvoiceData] = useState({
    companyName: "TECHNOCONNECT IT SOLUTIONS PVT LTD",
    companyAddress:
      "1934 Vishal Vihar Dubagga, Thakurganj, Lucknow, Uttar Pradesh, 226003",
    taxId: "09AAKCT2618Q1ZM",
    clientName: "",
    clientAddress: "",
    invoiceNumber: "",
    invoiceDate: "",
    dueDate: "",
    gstType: "Inclusive",
    items: [],
    discount: 0,
    subTotal: 0,
    tax: 0,
    total: 0,
    transactions: [],
    pdfGeneratedDate: new Date().toLocaleDateString(),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === "discount" ? parseFloat(value) || 0 : value;
    if (name === "invoiceDate") {
      const dueDate = moment(value).add(2, "days").format("YYYY-MM-DD");
      setInvoiceData({
        ...invoiceData,
        [name]: newValue,
        dueDate,
      });
    } else {
      setInvoiceData({
        ...invoiceData,
        [name]: newValue,
      });
    }
  };

  const addItem = () => {
    setInvoiceData((prevData) => ({
      ...prevData,
      items: [
        ...prevData.items,
        { itemId: "", quantity: 1, rate: 0, subtotal: 0 },
      ],
    }));
  };
  const removeItem = index => {
    setInvoiceData(prevData => ({
      ...prevData,
      items: prevData.items.filter((_, idx) => idx !== index)
    }));
  };
  const handleItemChange = (e, index) => {
    const { name, value } = e.target;
    const updatedItems = invoiceData.items.map((item, idx) => {
      if (idx === index) {
        const updatedItem = { ...item, [name]: value };
        const selectedItem = itemsList.find(
          (it) => it.id === updatedItem.itemId
        );
        if (name === "itemId" && selectedItem) {
          updatedItem.rate = selectedItem.price;
          updatedItem.subtotal = selectedItem.price * updatedItem.quantity;
        }
        if (name === "quantity" && selectedItem) {
          updatedItem.subtotal = selectedItem.price * value;
        }
        return updatedItem;
      }
      return item;
    });

    setInvoiceData((prevData) => ({
      ...prevData,
      items: updatedItems,
    }));
  };

  const handleGSTTypeChange = (e) => {
    setInvoiceData({
      ...invoiceData,
      gstType: e.target.value,
    });
  };

  const addTransaction = () => {
    setInvoiceData((prevData) => ({
      ...prevData,
      transactions: [
        ...prevData.transactions,
        { date: prevData.invoiceDate, transactionId: "", amount: 0 },
      ],
    }));
  };

  const handleTransactionChange = (e, index) => {
    const { name, value } = e.target;
    setInvoiceData((prevData) => {
      const newTransactions = [...prevData.transactions];
      newTransactions[index] = { ...newTransactions[index], [name]: value };
      return { ...prevData, transactions: newTransactions };
    });
  };

  const removeTransaction = (index) => {
    setInvoiceData((prevData) => {
      const newTransactions = [...prevData.transactions];
      newTransactions.splice(index, 1);
      return { ...prevData, transactions: newTransactions };
    });
  };

  const calculateTotals = () => {
    let subTotal = invoiceData.items.reduce((acc, item) => {
      const selectedItem = itemsList.find((i) => i.id === item.itemId);
      return acc + (selectedItem ? selectedItem.price * item.quantity : 0);
    }, 0);
    let tax = 0;
    let total = 0;

    if (invoiceData.gstType === "Exclusive") {
      tax = subTotal * 0.18;
      total = subTotal + tax;
    } else {
      total = subTotal;
      subTotal = total / 1.18;
      tax = total - subTotal;
    }
    total -= invoiceData.discount; // Apply discount
    setInvoiceData((prevData) => ({ ...prevData, subTotal, tax, total }));
  };

  const formatDateString = (dateString) => {
    return moment(dateString).format("dddd, MMMM Do, YYYY");
  };

  const formatItemDescription = (item) => {
    const startDate = moment(invoiceData.invoiceDate).format("DD/MM/YYYY");
    const endDate = moment(startDate, "DD/MM/YYYY")
      .add(1, "months")
      .format("DD/MM/YYYY");
    return `${item.description}\n${item.vCPU} vCPU\n${item.ram}GB RAM\n${item.ssd}GB SSD\n(${startDate} - ${endDate})`;
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const imgSrc = "./logo.jpeg";
    const paidSrc = "./paid.png"; // Ensure this path points to your actual logo image

    // Adding the company logo
    doc.addImage(imgSrc, "JPEG", 15, 10, 72, 30); // Adjust dimensions as needed
    doc.addImage(paidSrc,"PNG",160,0, 50,37.5)

    const x = 20;
    // Adding company information next to the logo
    doc.setFont("times-new-roman");
    doc.setFontSize(14);
    doc.text(invoiceData.companyName, 96, 15 + x);
    doc.setFontSize(8);
    doc.text(invoiceData.companyAddress, 110, 20 + x);
    doc.text(`GSTIN: ${invoiceData.taxId}`, 159, 25 + x);

    // Adding a gray box for invoice details
    doc.setFillColor(220, 220, 220); // Light gray
    doc.rect(15, 30 + x, 180, 25, "F"); // x, y, width, height, style
    doc.setTextColor(20, 20, 20); // Dark gray text
    doc.setFontSize(13);
    doc.setFont("times-new-roman", "bold");
    doc.text(`Invoice #${invoiceData.invoiceNumber}`, 16, 35 + x);
    doc.setFontSize(10);
    doc.setTextColor(50, 50, 50); // Dark gray text
    doc.setFont("helvetica", "normal");
    doc.text(
      `Invoice Date: ${moment(invoiceData.invoiceDate).format(
        "dddd, MMMM Do, YYYY"
      )}`,
      16,
      45 + x
    );
    doc.text(
      `Due Date: ${moment(invoiceData.dueDate).format("dddd, MMMM Do, YYYY")}`,
      16,
      50 + x
    );

    // Client information
    doc.setFontSize(11);
    doc.setFont("times-new-roman", "bold");
    doc.setTextColor(20, 20, 20);
    doc.text("Invoiced To:", 16, 65 + x);
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.setTextColor(0, 0, 0);
    doc.text(invoiceData.clientName, 16, 70 + x);
    doc.text(invoiceData.clientAddress, 16, 80 + x);
    doc.text("India", 16, 84 + x);

    // Items Table
    doc.autoTable({
      startY: 90 + x,
      head: [["Description", "Rate", "Quantity", "Subtotal"]],
      body: invoiceData.items.map((item) => {
        const selectedItem = itemsList.find((i) => i.id === item.itemId);
        const itemDescription = formatItemDescription(selectedItem);
        const itemSubtotal = selectedItem
          ? (selectedItem.price * item.quantity).toFixed(2)
          : "0.00";
        return [
          itemDescription,
          `Rs. ${selectedItem?.price.toFixed(2)}`,
          item.quantity,
          `Rs. ${itemSubtotal}`,
        ];
      }),
      headStyles: {
        fillColor: [220, 220, 220], // Set header background color to rgb(220,220,220)
        textColor: [0, 0, 0], // Set text color to black
        fontSize: 10, // Increase the font size
        fontStyle: "bold", // Make the font bold for better visibility
      },
      bodyStyles: {
        fontSize: 9, // Set body font size to 8
      },
      tableWidth: 180,
    });
    // Subtotals and totals
    const tableBottomY = doc.autoTable.previous.finalY + 10;
    doc.setFontSize(9);
    doc.setFillColor(220, 220, 220);
    doc.rect(14, tableBottomY - 5, 135, 7, "F"); // x, y, width, height, style
    doc.text("Discount", 135, tableBottomY);
    doc.setFillColor(220, 220, 220);
    doc.rect(150, tableBottomY - 5, 44, 7, "F");
    doc.text(
      `Rs. ${parseFloat(invoiceData.discount).toFixed(2)} INR`,
      155,
      tableBottomY
    );
    doc.setFillColor(220, 220, 220);
    doc.rect(14, tableBottomY + 3, 135, 7, "F");
    doc.text("Sub Total", 134, tableBottomY + 8);
    doc.setFillColor(220, 220, 220);
    doc.rect(150, tableBottomY + 3, 44, 7, "F");
    doc.text(
      `Rs. ${parseFloat(invoiceData.subTotal).toFixed(2)} INR`,
      155,
      tableBottomY + 8
    );

    doc.setFillColor(220, 220, 220);
    doc.rect(14, tableBottomY + 11, 135, 7, "F");
    doc.text("18.00% GST / TAX", 121, tableBottomY + 16);
    doc.setFillColor(220, 220, 220);
    doc.rect(150, tableBottomY + 11, 44, 7, "F");
    doc.text(
      `Rs. ${parseFloat(invoiceData.tax).toFixed(2)} INR`,
      155,
      tableBottomY + 16
    );

    doc.setFont("helvetica", "bold");
    doc.setFillColor(220, 220, 220);
    doc.rect(14, tableBottomY + 19, 135, 7, "F");
    doc.text("Total", 140, tableBottomY + 24);
    doc.setFillColor(220, 220, 220);
    doc.rect(150, tableBottomY + 19, 44, 7, "F");
    doc.text(
      `Rs. ${parseFloat(invoiceData.total).toFixed(2)} INR`,
      155,
      tableBottomY + 24
    );

    doc.setFontSize(12);
    doc.setFont("times-new-roman", "bold");
    doc.setTextColor(20, 20, 20);
    doc.text("Transactions", 16, tableBottomY + 45);

    doc.setFont("helvetica", "normal");
    // Transactions table
    doc.autoTable({
      startY: tableBottomY + 50,
      head: [["Transaction Date", "Gateway", "Transaction ID", "Amount"]],
      body: invoiceData.transactions.map((transaction) => [
        formatDateString(transaction.date),
        "UPI (India)",
        transaction.transactionId,
        `Rs. ${parseFloat(transaction.amount).toFixed(2)} INR`,
      ]),
      columnStyles: {
        0: { cellWidth: 58, valign: "middle" },
        1: { cellWidth: 30, align: "center", valign: "middle" },
        2: { cellWidth: 50, align: "center", valign: "middle" },
        3: { cellWidth: 40, align: "right", valign: "middle" },
      },
      headStyles: {
        fillColor: [220, 220, 220], // Set header background color to rgb(220,220,220)
        textColor: [0, 0, 0], // Set text color to black
        fontSize: 10, // Increase the font size
        fontStyle: "bold", // Make the font bold for better visibility
      },
      bodyStyles: {
        fontSize: 9, // Set body font size to 8
      },
      tableWidth: 180,
    });

    // Balance
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.setFillColor(220, 220, 220);
    doc.rect(14, doc.autoTable.previous.finalY, 135, 7, "F");
    doc.text("Balance", 134, doc.autoTable.previous.finalY + 5);
    doc.setFillColor(220, 220, 220);
    doc.rect(150, doc.autoTable.previous.finalY, 44, 7, "F");
    doc.text(
      `Rs. ${(
        parseFloat(invoiceData.total) -
        invoiceData.transactions.reduce(
          (acc, t) => acc + parseFloat(t.amount || 0),
          0
        )
      ).toFixed(2)} INR`,
      155,
      doc.autoTable.previous.finalY + 5
    );
    doc.setFont("helvetica", "normal");

    // PDF Generation Date
    doc.setFontSize(8);
    doc.text(
      `PDF Generated on ${moment(invoiceData.pdfGeneratedDate).format(
        "dddd, MMMM Do YYYY,"
      )}`,
      78,
      doc.autoTable.previous.finalY + 30
    );

    // Save the PDF
    doc.save(`Invoice-${invoiceData.invoiceNumber}.pdf`);
  };

  return (
    <div class="container">
        <div class="header">
            <h1>Invoice Creator</h1>
            
        </div>
        <form>
            <div>
                <label>Client Name:</label>
                <input type="text" name="clientName" value={invoiceData.clientName} onChange={handleInputChange} />
            </div>
            <div>
                <label>Client Address:</label>
                <input type="text" name="clientAddress" value={invoiceData.clientAddress} onChange={handleInputChange} />
            </div>
            <div>
                <label>Invoice Number:</label>
                <input type="text" name="invoiceNumber" value={invoiceData.invoiceNumber} onChange={handleInputChange} />
            </div>
            <div>
                <label>Invoice Date:</label>
                <input type="date" name="invoiceDate" value={invoiceData.invoiceDate} onChange={handleInputChange} />
            </div>
            <div>
                <label>Due Date:</label>
                <input type="date" name="dueDate" value={invoiceData.dueDate} readOnly />
            </div>
            <div>
                <label>GST Type:</label>
                <select name="gstType" value={invoiceData.gstType} onChange={handleGSTTypeChange}>
                    <option value="Inclusive">Inclusive</option>
                    <option value="Exclusive">Exclusive</option>
                </select>
            </div>
            <div class="full-width">
                <label>Discount:</label>
                <input type="number" name="discount" value={invoiceData.discount} onChange={handleInputChange} placeholder="Enter discount amount" />
            </div>
            <div class="full-width">
                <h3>Items</h3>
                {invoiceData.items.map((item, index) => (
                    <div class="item" key={index}>
                        <div>
                            <select name="itemId" value={item.itemId} onChange={(e) => handleItemChange(e, index)}>
                                <option value="">Select an Item</option>
                                {itemsList.map((option) => (
                                    <option key={option.id} value={option.id}>{option.description} - {option.price}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <input type="number" name="quantity" placeholder="Quantity" value={item.quantity} onChange={(e) => handleItemChange(e, index)} />
                        </div>
                        <div>
                            <button type="button" onClick={() => removeItem(index)}>Remove Item</button>
                        </div>
                    </div>
                ))}
                <button type="button" onClick={addItem}>Add Item</button>
            </div>
            <div class="full-width">
                <h3>Transactions</h3>
                {invoiceData.transactions.map((transaction, index) => (
                    <div class="transaction" key={index}>
                        <div>
                            <label>Transaction Date:</label>
                            <input type="date" name="date" value={transaction.date} onChange={(e) => handleTransactionChange(e, index)} />
                        </div>
                        <div>
                            <label>Transaction ID:</label>
                            <input type="text" name="transactionId" value={transaction.transactionId} onChange={(e) => handleTransactionChange(e, index)} />
                        </div>
                        <div>
                            <label>Amount:</label>
                            <input type="number" name="amount" value={transaction.amount} onChange={(e) => handleTransactionChange(e, index)} />
                        </div>
                        <div>
                            <button type="button" onClick={() => removeTransaction(index)}>Remove Transaction</button>
                        </div>
                    </div>
                ))}
                <button type="button" onClick={addTransaction}>Add Transaction</button>
            </div>
            <div class="full-width">
                <p><b>Total:</b> {invoiceData.total} <br/><b>Balance:</b> {(parseFloat(invoiceData.total) - invoiceData.transactions.reduce((acc, t) => acc + parseFloat(t.amount || 0), 0)).toFixed(2)}</p>
                <p><b>Details</b><br/>Invoice NO: {invoiceData.invoiceNumber}<br/>Name: {invoiceData.clientName}<br/>Address: {invoiceData.clientAddress}<br/>Date: {invoiceData.invoiceDate}</p>
                <button type="button" onClick={calculateTotals}>Calculate Totals</button>
                <button type="button" onClick={generatePDF}>Generate PDF</button>
            </div>
        </form>
    </div>
  );
};

export default App;
